import axios from "axios";
import { APIType } from "./types";

const REQUEST: any = axios.create({ baseURL: "", responseType: "json" });

interface IHeaders {
  (token: string | null, contentType: string | null): any;
}

type ContentType = {
  Accept?: string;
  ["Content-Type"]?: string;
  Authorization?: string;
};

const setHeaders: IHeaders = (token, contentType) => {
  let headers: ContentType = {
    Accept: "application/json",
  };

  if (contentType) headers["Content-Type"] = contentType;
  if (token) headers.Authorization = `Bearer ${token}`;

  return { headers };
};

interface IAPIObject {
  get: (e: APIType) => any;
  post: (e: APIType) => any;
  put: (e: APIType) => any;
  delete: (e: APIType) => any;
}

export const API: IAPIObject = {
  get: ({ baseUrl, endpoint, token = null, contentType = null, success, failure }: APIType) => {
    REQUEST.defaults.baseURL = baseUrl;
    return REQUEST.get(endpoint, setHeaders(token, contentType))
      .then(async (response: any) => await success(response))
      .catch((error: any) => failure(error));
  },
  post: ({ baseUrl, endpoint, token = null, contentType = null, data, success, failure }: APIType) => {
    REQUEST.defaults.baseURL = baseUrl;
    return REQUEST.post(endpoint, data, setHeaders(token, contentType))
      .then(async (response: any) => await success(response))
      .catch((error: any) => failure(error));
  },
  put: ({ baseUrl, endpoint, token = null, contentType = null, data, success, failure }: APIType) => {
    REQUEST.defaults.baseURL = baseUrl;
    return REQUEST.put(endpoint, data, setHeaders(token, contentType))
      .then(async (response: any) => await success(response))
      .catch((error: any) => failure(error));
  },
  delete: ({ baseUrl = null, endpoint, token = null, contentType = null, success, failure }: APIType) => {
    REQUEST.defaults.baseURL = baseUrl;
    return REQUEST.delete(endpoint, setHeaders(token, contentType))
      .then(async (response: any) => await success(response))
      .catch((error: any) => failure(error));
  },
};

// Usage Example
// const usageExample = () => {
//     API.post({
//         endpoint: 'auth/login',
//         // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9',
//         // contentType: 'application/json',
//         data: {},
//         success: (response) => {},
//         failure: (error) => {}
//     });
// }

// API.post({
//     baseUrl: 'https://',
//     endpoint: 'auth/login',
//     // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9',
//     // contentType: 'application/json',
//     data: {},
//     success: (response) => {},
//     failure: (error) => {}
// });
