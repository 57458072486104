import { motion } from "framer-motion";
import React from "react";
interface IProps {
  width?: "full" | "half";
  shadow?: boolean;
  rounded?: boolean;
  children?: React.ReactNode | JSX.Element | JSX.Element[];
  centerContent?: boolean;
  initial?: any;
  animate?: any;
  transition?: any;
}
export default function Content(props: IProps) {
  const { width, shadow, rounded, children, centerContent, initial = {}, animate = {}, transition = {} } = props;
  return (
    <motion.div
      initial={initial}
      animate={animate}
      transition={transition}
      className={`bg-white ${width === "half" ? "w-full xl:w-6/12" : "w-full"} p-4 ${shadow ? "shadow" : ""} ${
        rounded ? "rounded-md" : ""
      } min-h-32 ${centerContent ? "flex justify-center items-center" : ""}`}
    >
      {children}
    </motion.div>
  );
}
